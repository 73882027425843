import { React } from "react";

const codigoImagen = (undestino, preidioma, ruta, t, className, aClass, index) => {
    return (
        <a href={`/${preidioma}${t("apartamentos")}-${undestino.laurl}`} className={`caixa-destins ${aClass}`} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
            {
                undestino.imagen_principal.length > 0 ?
                    <img
                        className={`${className}`}
                        src={`${ruta}/destinos/${undestino.id}/${undestino.imagen_principal[0].imagen}`}
                        alt={undestino.elnombre}
                    />
                    :
                    <img
                        className={`${className}`}
                        src="/images/web/bedloop-nofoto.png"
                        alt={undestino.elnombre}
                    />
            }
            <span className="sobreimagen">{undestino.elnombre}</span>
        </a>
    );
};

export default codigoImagen;
